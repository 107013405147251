import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'
import { Facebook, Instagram } from 'react-feather'
import LazyLoad from 'react-lazyload'

export interface Props {
  facebookURL?: string
  hashtag?: string
  images?: string[]
  instagramURL?: string
  title?: string
}

export const Social = memo(function Social({
  facebookURL,
  hashtag,
  images,
  instagramURL,
  title,
}: Props) {
  return (
    <Container>
      <Head dial={4} row>
        <Text>
          <div>
            {title ? <Title>{title}</Title> : null}
            {hashtag ? <Hashtag>{hashtag}</Hashtag> : null}
          </div>
        </Text>
        <SocialWrap row>
          {facebookURL ? (
            <SocialItem
              aria-label="Facebook"
              href={facebookURL}
              rel="noreferrer"
              target="_blank"
            >
              <Facebook />
            </SocialItem>
          ) : null}
          {instagramURL ? (
            <SocialItem
              aria-label="Instagram"
              href={instagramURL}
              rel="noreferrer"
              target="_blank"
            >
              <Instagram />
            </SocialItem>
          ) : null}
        </SocialWrap>
      </Head>
      {images ? (
        <Grid>
          <Wrapper row wrap>
            {uniq(images)
              .slice(0, 8)
              .map((item, index) => (
                <LazyLoad key={index}>
                  <Image style={{ backgroundImage: `url(${item})` }} />
                </LazyLoad>
              ))}
          </Wrapper>
        </Grid>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin-top: 9.75rem;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
  }
`

const Head = styled(FlexBox)`
  max-width: 56.5rem;
  margin: auto;
  padding: 0 1.5rem;
`

const Text = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;
  &:after {
    content: '';
    width: 100%;
    height: 1.5px;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin: 0 2.5rem;
  }

  @media (max-width: 767px) {
    &:after {
      margin: 0 1.125rem 0 2.25rem;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
`

const Hashtag = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: 0.8px;
  line-height: 1.125rem;
  margin-top: 0.375rem;
  text-transform: uppercase;
`

const SocialWrap = styled(FlexBox)``

const SocialItem = styled.a`
  margin-left: 1.25rem;
  &:hover {
    svg {
      stroke: ${({ theme }) => theme.colors.variants.primaryDark1};
    }
  }
  svg {
    width: auto;
    height: 1.5rem;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark4};
    stroke-width: 1.5;
    transition: 0.3s ease-in-out;
  }
`

const Grid = styled.div`
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    position: absolute;
    bottom: 0;
    left: 0;
  }
`

const Wrapper = styled(FlexBox)`
  max-width: 56.5rem;
  margin: 3.75rem auto 0;
  padding: 0 1.5rem;
  position: relative;
  z-index: 2;
  .lazyload-wrapper {
    width: 25%;
    padding-bottom: 25%;
    position: relative;
  }
`

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
`
